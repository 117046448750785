import utils from "../utils/utils";
import anime from "../libs/anime";
import globalVar from "../utils/globalVar";

class PopUp {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.popUpClicks = document.querySelectorAll(".popup_click");
			cache.popUpClose = document.querySelectorAll(".popup .bg, .popup .close");
			cache.header = document.querySelector(".site-header");
			cache.breadcrumb = document.querySelector(".woocommerce-breadcrumb");
		};

		let registerEvents = () => {
			for (let i = 0; i < cache.popUpClicks.length; i++) {
				cache.popUpClicks[i].addEventListener("click", openPopUp);
			}
			for (let i = 0; i < cache.popUpClose.length; i++) {
				cache.popUpClose[i].addEventListener("click", closePopUp);
			}
		};

		// Fix du contact form en popup
		let preventSpacebarIssues = (popup) => {
			popup.addEventListener("keydown", function(event) {
				if (
					(event.key === " " && event.target.tagName === "INPUT") ||
					event.target.tagName === "TEXTAREA"
				) {
					event.stopPropagation();
				}
			});
		};

		let openPopUp = function() {
			utils.disableScroll();
			const el = this;
			const popup_class = el.dataset.popup;
			const popup = document.querySelector("." + popup_class);

			popup.style.display = "block";

			anime({
				targets: popup,
				opacity: [0, 1],
				duration: 400,
				easing: "easeOutQuad",
			});

			if (popup.classList.contains("popup-try-in-store")) {
				anime({
					targets: popup.querySelector(".inner"),
					translateX: ["-50%", "-50%"],
					translateY: ["-50%", "-50%"],
					scale: [0.9, 1],
					delay: 100,
					duration: 500,
					easing: "easeOutExpo",
				});

				cache.header.style.zIndex = "1";
				cache.breadcrumb.style.zIndex = "1";
			} else if (popup.classList.contains("popup-compatibility")) {
				anime({
					targets: popup.querySelector(".inner"),
					translateX: ["-50%", "-50%"],
					translateY: ["calc(-50% + 70px)", "calc(-50% + 70px)"],
					scale: [0.9, 1],
					duration: 500,
					easing: "easeOutExpo",
				});
			} else {
				anime({
					targets: popup.querySelector(".inner"),
					translateX: ["-50%", "-50%"],
					translateY: ["calc(-50% + 50px)", "calc(-50% + 50px)"],
					scale: [0.9, 1],
					delay: 100,
					duration: 500,
					easing: "easeOutExpo",
				});
			}

			// Active le scroll interne pour popup try in store
			if (popup.classList.contains("popup-try-in-store")) {
				popup.style.overflowY = "auto";
				popup.addEventListener("wheel", function(e) {
					e.stopPropagation();
				});
			}

			preventSpacebarIssues(popup);

			// resize de la map
			if (window.map) {
				setTimeout(() => {
					window.map.resize();
				}, 200);
			}

			const iframe = popup.querySelector("iframe");
			if (iframe) {
				iframe.contentWindow.postMessage(
					'{"event":"command","func":"playVideo","args":""}',
					"*"
				);
			}

			const video = popup.querySelector("video");
			if (video) {
				video.play();
			}
		};

		let closePopUp = function() {
			const popup = this.closest(".popup");

			anime({
				targets: popup,
				opacity: [1, 0],
				duration: 400,
				delay: 100,
				easing: "easeOutQuad",
				complete: function() {
					popup.style.display = "none";
					utils.enableScroll();
					if (popup.querySelector("iframe")) {
						const iframe = popup.querySelector("iframe");
						const src = iframe.src;
						iframe.src = "";
						iframe.src = src;
					} else if (popup.querySelector("video")) {
						const vidPlayer = popup.querySelector("video");
						vidPlayer.pause();
					}
				},
			});

			if (popup.classList.contains("popup-try-in-store")) {
				anime({
					targets: popup.querySelector(".inner"),
					translateX: ["-50%", "-50%"],
					translateY: ["-50%", "-50%"],
					scale: [1, 0.9],
					duration: 500,
					easing: "easeOutExpo",
				});

				cache.header.style.zIndex = "1002";
				cache.breadcrumb.style.zIndex = "10";
			} else if (popup.classList.contains("popup-compatibility")) {
				anime({
					targets: popup.querySelector(".inner"),
					translateX: ["-50%", "-50%"],
					translateY: ["calc(-50% + 70px)", "calc(-50% + 70px)"],
					scale: [1, 0.9],
					duration: 500,
					easing: "easeOutExpo",
				});
			} else {
				anime({
					targets: popup.querySelector(".inner"),
					translateX: ["-50%", "-50%"],
					translateY: ["calc(-50% + 50px)", "calc(-50% + 50px)"],
					scale: [1, 0.9],
					duration: 500,
					easing: "easeOutExpo",
				});
			}
		};

		initFunc();
	}
}
export { PopUp };
