class PopUpLocalisation {
	constructor() {
		let cache = {};
		const countryLanguages = {
			france: ["fr", "en"],
			belgium: ["fr", "en"],
			luxembourg: ["fr", "en"],
			germany: ["de"],
			austria: ["de"],
			italy: ["it"],
			usa: ["en"],
			canada: ["en"],
			international: ["en"],
			netherlands: ["en"],
			spain: ["en"],
			"czech-republic": ["en"],
			denmark: ["en"],
			estonia: ["en"],
			hungary: ["en"],
			ireland: ["en"],
			poland: ["en"],
			portugal: ["en"],
			slovakia: ["en"],
			slovenia: ["en"],
			croatia: ["en"],
			latvia: ["en"],
			lithuania: ["en"],
			sweden: ["en"],
			bulgaria: ["en"],
			cyprus: ["en"],
			finland: ["en"],
			greece: ["en"],
			malta: ["en"],
			romania: ["en"],
		};

		let initFunc = () => {
			initCache();
			registerEvents();
			if (!shouldShowPopup()) return; // Ne montre pas le popup si déjà validé ou délai écoulé
            populateSelects(); // Initialise les sélections
		};

		let initCache = () => {
			cache.btnLanguage = document.querySelectorAll(".btn-language");
			cache.popupLocalisation = document.querySelector(".popup-localisation");
			cache.closePopupLocalisation = document.getElementById("close-popup-localisation");

			cache.submitPopup = document.querySelector(".popup-localisation .btn");
			cache.languageSelect = document.getElementById("language-select");
			cache.countrySelect = document.getElementById("country-select");
		};

		let registerEvents = () => {
			setTimeout(() => {
				if (!localStorage.getItem("popupValidated") && cache.popupLocalisation) {
					cache.popupLocalisation.classList.add("open");
				}
			}, 500);

			if (cache.closePopupLocalisation) {
				cache.closePopupLocalisation.addEventListener("click", closePopupLocalisation);
			}

			if (cache.btnLanguage) {
				cache.btnLanguage.forEach(btn => {
					btn.addEventListener("click", openPopupLocalisation);
				});
			}

			if (cache.submitPopup) {
				cache.submitPopup.addEventListener("click", applyChanges);
			}

			if (cache.countrySelect) {
				cache.countrySelect.addEventListener("change", updateLanguages);
			}
		};

		let shouldShowPopup = () => {
			const popupValidated = localStorage.getItem("popupValidated");
			const lastShown = parseInt(localStorage.getItem("popupLastShown"), 10);
			const now = Date.now();

			// Vérifie si le popup a été validé et si la période de 10 jours n'est pas écoulée
			if (popupValidated && lastShown && now - lastShown < 10 * 24 * 60 * 60 * 1000) {
				console.log("Popup already validated and within 10 days. Skipping...");
				return false;
			}

			// Si aucune validation ou délai écoulé, affiche le popup
			return true;
		};

		let openPopupLocalisation = () => {
			localStorage.removeItem("popupValidated");
			cache.popupLocalisation.classList.add("open");
		};

		let closePopupLocalisation = () => {
			cache.popupLocalisation.classList.remove("open");
		};


		let applyChanges = () => {
			const selectedLanguage = cache.languageSelect ? cache.languageSelect.value : null;
			const selectedCountry = cache.countrySelect ? cache.countrySelect.value : null;

			if (!selectedCountry) {
				console.error("Country not selected properly.");
				return;
			}

			// Gestion des redirections spécifiques pour certains pays
			if (selectedCountry === "italy") {
				window.location.href = "https://yubabikes.it";
				return;
			} else if (selectedCountry === "usa" || selectedCountry === "canada") {
				window.location.href = "https://yubabikes.com";
				return;
			}

			// Mise à jour des préférences utilisateur
			localStorage.setItem("popupValidated", "true");
			localStorage.setItem("popupLastShown", Date.now().toString()); // Stocke l'horodatage
			localStorage.setItem("currentCountry", selectedCountry);
			localStorage.setItem("currentLanguage", selectedLanguage);

			console.log(`Confirmed: Language = ${selectedLanguage}, Country = ${selectedCountry}`);

			// Récupération des URLs traduites via le script PHP intégré
			if (window.translatedUrls && window.translatedUrls[selectedLanguage]) {
				// Redirige vers la page traduite si elle existe
				window.location.href = window.translatedUrls[selectedLanguage];
			} else {
				// Redirige vers la page d'accueil de la langue sélectionnée si la page traduite n'existe pas
				const homepage = `/${selectedLanguage}/`;
				window.location.href = homepage;
			}
		};

		let updateLanguages = () => {
			const selectedCountry = cache.countrySelect.value;
			const availableLanguages = countryLanguages[selectedCountry] || ["en"];

			if (cache.languageSelect) {
				// Clear existing options
				cache.languageSelect.innerHTML = "";

				// Add new options
				availableLanguages.forEach(language => {
					const option = document.createElement("option");
					option.value = language;
					option.textContent =
					language === "fr"
					? "French"
					: language === "de"
					? "German"
					: language === "it"
					? "Italian"
					: "English";
					cache.languageSelect.appendChild(option);
				});

				// Set default selected language
				cache.languageSelect.value = availableLanguages[0] || "en";

				console.log(`Updated languages for ${selectedCountry}:`, availableLanguages);
			}
		};


		let populateSelects = () => {
    const currentLanguage = localStorage.getItem("currentLanguage") || window.geotLanguage || "en";
    const currentCountry = localStorage.getItem("currentCountry") || window.geotCountry || "international";

    console.log("Detected from LocalStorage:", currentLanguage, currentCountry);
    console.log("Fallback to window vars:", window.geotLanguage, window.geotCountry);

    // Appliquer le pays sélectionné
    if (cache.countrySelect) {
        Array.from(cache.countrySelect.options).forEach(option => {
            option.selected = option.value === currentCountry.toLowerCase();
        });
        cache.countrySelect.value = currentCountry.toLowerCase();
    }

    // Mettre à jour les langues disponibles pour le pays sélectionné
    updateLanguages();

    // Appliquer la langue sélectionnée
    if (cache.languageSelect) {
        if ([...cache.languageSelect.options].some(option => option.value === currentLanguage)) {
            cache.languageSelect.value = currentLanguage;
        } else {
            cache.languageSelect.value = "en"; // Par défaut "en" si non disponible
        }
    }
};
		initFunc();
	}
}

export { PopUpLocalisation };
