import utils from "../../utils/utils";

class MiniCart {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.cartButton = document.getElementById("cart-button");
			cache.cartButtonMobile = document.getElementById("cart-button-mobile");
			cache.miniCartWrapper = document.getElementById("mini-cart-wrapper");
			cache.miniCart = document.getElementById("mini-cart");
			cache.closeCartButton = document.getElementById("close-mini-cart");
		};

		let registerEvents = () => {
			if (cache.cartButton) {
				cache.cartButton.addEventListener("click", function() {
					cache.miniCartWrapper.classList.toggle("hidden");
					cache.miniCart.classList.toggle("hidden");
					loadMiniCart();
				});
			}

			if (cache.cartButtonMobile) {
				cache.cartButtonMobile.addEventListener("click", function() {
					cache.miniCartWrapper.classList.toggle("hidden");
					cache.miniCart.classList.toggle("hidden");
					utils.disableScroll();
					loadMiniCart();
				});
			}

			cache.closeCartButton.addEventListener("click", function() {
				cache.miniCartWrapper.classList.add("hidden");
				cache.miniCart.classList.add("hidden");
				utils.enableScroll();
			});

			document.addEventListener("click", function(event) {
				if (event.target.closest(".increment")) {
					const input = event.target
						.closest(".custom-quantity")
						.querySelector("input.qty");
					input.stepUp();
					input.dispatchEvent(new Event("change"));
				} else if (event.target.closest(".decrement")) {
					const input = event.target
						.closest(".custom-quantity")
						.querySelector("input.qty");
					input.stepDown();
					input.dispatchEvent(new Event("change"));
				}
			});
		};

		let loadMiniCart = () => {
			fetch(wc_cart_params.ajax_url, {
				method: "POST",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body: "action=get_mini_cart_content",
			})
				.then((response) => response.json())
				.then((data) => {
					// Met à jour le contenu du mini-panier
					document.querySelector(".mini-cart-items").innerHTML = data.content;

					// Met à jour le nombre d'articles dans le titre
					const itemCountElement = document.querySelector(
						"#mini-cart-item-count"
					);
					if (itemCountElement) {
						itemCountElement.textContent = data.item_count;
					}

					attachQuantityChangeListeners();
				})
				.catch((error) => console.error("Erreur:", error));
		};

		function attachQuantityChangeListeners() {
			document
				.querySelectorAll(".mini-cart-quantity input.qty")
				.forEach(function(input) {
					input.addEventListener("change", function() {
						let cartKey = this.name.match(/\[([a-z0-9]+)\]/)[1];
						let quantity = parseInt(this.value, 10);

						updateMiniCartItem(cartKey, quantity);
					});
				});
		}

		let updateMiniCartItem = (cartKey, quantity) => {
			jQuery.ajax({
				url: wc_cart_params.ajax_url,
				type: "POST",
				data: {
					action: "update_cart_item",
					cart_key: cartKey,
					quantity: quantity,
				},
				success: function() {
					loadMiniCart();
				},
				error: function(error) {
					console.error(
						"Erreur lors de la mise à jour de l'article du panier",
						error
					);
				},
			});
		};

		initFunc();
	}
}

export { MiniCart };
